import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import ErrorPage from "./pages/ErrorPage";
import MainLayout from "./pages/MainLayout";
import Services from "./pages/Services";
import Portfolio from "./pages/Portfolio";
import Blog from "./pages/Blog";
import ContactUs from "./pages/ContactUs";
import Job from "./pages/Job";
import Admin from "./pages/Admin"
import BlogDetail from "./pages/BlogDetail"

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "about",
          element: <About />,
        },
        {
          path: "services",
          element: <Services />,
        },
        {
          path: "portfolio",
          element: <Portfolio />,
        },
        // {
        //   path: "/casestudy/:id",
        //   element: <Portfolio />,
        // },

        // <Route path="/casestudy/:id" component={CaseStudy} /> {/* Example route for case study */}
        {
          path: "blog",
          element: <Blog />,
        },
        {
         path : "/blog/:id",
         element : <BlogDetail/>,
        },
        {
          path: "contact",
          element: <ContactUs />,
        },
        {
          path: "jobs",
          element: <Job/>,
        },
    
      ],
    },
    {
      path: "admin",
      element: <Admin />,
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
