import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Spin, message, Pagination, Card, Row, Col, Button } from 'antd';
import AOS from 'aos';
import 'aos/dist/aos.css';

const { Meta } = Card;

const BlogDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const [recentBlogs, setRecentBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(3);

  useEffect(() => {
    AOS.init(); // Initialize AOS
    fetchBlog();
    fetchRecentBlogs();
  }, [id]);

  const fetchBlog = async () => {
    try {
      const response = await axios.get(`https://ethioalert.api.ethioalert.com/api/posts/${id}`);
      setBlog(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching blog:', error);
      message.error('Failed to fetch blog');
      setLoading(false);
    }
  };

  const fetchRecentBlogs = async () => {
    try {
      const response = await axios.get('https://ethioalert.api.ethioalert.com/api/posts');
      setRecentBlogs(response.data.filter(blog => blog._id !== id));
    } catch (error) {
      console.error('Error fetching recent blogs:', error);
      message.error('Failed to fetch recent blogs');
    }
  };

  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedBlogs = recentBlogs.slice(startIndex, endIndex);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Spin size="large" />
      </div>
    );
  }

  if (!blog) {
    return <p className="text-center text-lg">Blog not found</p>;
  }

  return (
    <div className="container mx-auto p-4 lg:p-8">
      <Button 
        type="primary" 
        onClick={() => navigate(-1)} 
        className="mb-6 bg-blue-600 hover:bg-blue-700 text-white rounded-lg shadow-md"
        icon={<i className="fa fa-arrow-left"></i>}
      >
        Back
      </Button>
      <div className="mb-6">
        <img
          alt={blog.title}
          src={`https://ethioalert.api.ethioalert.com/${blog.imageURL}`}
          className="w-full h-[500px] object-cover rounded-lg shadow-lg"
          data-aos="fade-up"
          data-aos-duration="1000"
        />
      </div>
      <h1 className="text-4xl font-extrabold mb-4" data-aos="fade-up" data-aos-duration="1000">{blog.title}</h1>
      <p className="text-sm text-gray-500 mb-4" data-aos="fade-up" data-aos-duration="1000">{new Date(blog.date).toLocaleDateString()}</p>
      <div className="prose lg:prose-xl" data-aos="fade-up" data-aos-duration="1000">
        <p>{blog.content}</p>
      </div>

      <div className="mt-12">
        <h2 className="text-3xl font-bold mb-6" data-aos="fade-up" data-aos-duration="1000">Recent Blogs</h2>
        <Row gutter={16}>
          {paginatedBlogs.map((recentBlog) => (
            <Col key={recentBlog._id} xs={24} sm={12} md={8} data-aos="fade-up" data-aos-duration="1000">
              <Card
                hoverable
                cover={
                  <div className="relative h-[250px]">
                    <img
                      alt={recentBlog.title}
                      src={`https://ethioalert.api.ethioalert.com/${recentBlog.imageURL}`}
                      className="w-full h-full object-cover rounded-t-lg"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    />
                  </div>
                }
                className="shadow-lg rounded-lg transition-transform transform hover:scale-105"
              >
                <Meta
                  title={<span className="font-semibold text-lg">{recentBlog.title.length > 100 ? `${recentBlog.title.slice(0, 100)}...` : recentBlog.title}</span>}
                  description={<span className="text-sm text-gray-700">{recentBlog.content.length > 100 ? `${recentBlog.content.slice(0, 100)}...` : recentBlog.content}</span>}
                />
                <Link 
                  to={`/blog/${recentBlog._id}`} 
                  className="block mt-4 text-center bg-blue-600 text-white rounded-lg py-2 hover:bg-blue-700 transition-colors"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  Read More
                </Link>
              </Card>
            </Col>
          ))}
        </Row>

        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={recentBlogs.length}
          onChange={handlePaginationChange}
          className="mt-8"
        />
      </div>
    </div>
  );
};

export default BlogDetail;
