import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Jobs = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const jobListings = [
    {
      id: 1,
      title: "Frontend Developer",
      location: "Addis Ababa, Ethiopia",
      company: "Ethio Alert",
      description: "We are looking for a skilled Frontend Developer to join our team and contribute to the development of our web applications.",
      requirements: ["Proficiency in HTML, CSS, JavaScript", "Experience with React.js", "Strong problem-solving skills"],
      applyLink: "https://example.com/apply/frontend-developer",
    },
    {
      id: 2,
      title: "Graphic Designer",
      location: "Remote",
      company: "Ethio Alert",
      description: "Seeking a creative Graphic Designer to design engaging visuals for our digital platforms and marketing materials.",
      requirements: ["Proficiency in Adobe Creative Suite", "Strong portfolio of graphic design work", "Excellent communication skills"],
      applyLink: "https://example.com/apply/graphic-designer",
    },
    {
      id: 3,
      title: "Digital Marketer",
      location: "Addis Ababa, Ethiopia",
      company: "Ethio Alert",
      description: "Join our team as a Digital Marketer to strategize and execute digital campaigns that drive growth and engagement.",
      requirements: ["Experience in SEO, SEM, and social media marketing", "Analytical mindset with a focus on data-driven decision making", "Excellent written and verbal communication skills"],
      applyLink: "https://example.com/apply/digital-marketer",
    },
  ];

  return (
    <div className="bg-slate-700 py-12">
      <div className="max-w-7xl mx-auto px-6 sm:px-10 md:px-14">
        <h2 className="text-3xl md:text-4xl font-bold text-yellow-600 text-center mb-12" data-aos="fade-up">
          Job Opportunities
        </h2>
        {jobListings.map((job) => (
          <div key={job.id} className="bg-white shadow-md rounded-lg overflow-hidden mb-8" data-aos="fade-up">
            <div className="p-8">
              <h3 className="text-2xl font-semibold text-gray-800 mb-2">{job.title}</h3>
              <p className="text-gray-600 mb-4">{job.company} - {job.location}</p>
              <p className="text-gray-700 mb-6">{job.description}</p>
              <h4 className="text-lg font-semibold text-gray-800 mb-4">Requirements:</h4>
              <ul className="list-disc list-inside mb-6 space-y-2">
                {job.requirements.map((requirement, index) => (
                  <li key={index} className="text-gray-700">{requirement}</li>
                ))}
              </ul>
              <a
                href={job.applyLink}
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded transition-colors duration-300"
              >
                Apply Now
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Jobs;
