import React from 'react'
import Aboutc from "../components/About/AboutC"
import AboutDesc from '../components/About/AboutDesc'
import MissionVision from '../components/About/MissionVision'
import CEO from "../components/About/CEO"
import Team from "../components/About/Team"
import { Helmet } from 'react-helmet';
function About() {
  return (
    <div>
       <Helmet>
        <title>About Us - Ethio Alert</title>
        <meta name="description" content="About us page of Ethio Alert" />
      </Helmet>
      <Aboutc/>
      <AboutDesc/>
      <CEO/>
      <Team/>
      <MissionVision/>
      
    </div>
  )
}

export default About