import React from 'react'
import Portfolioo from "../components/Portfolio/PortfolioOne"
import PortfolioTwo from "../components/Portfolio/PortfolioTwo"
import { Helmet } from 'react-helmet';

function Portfolio() {
  return (
    <div>
      <Helmet>
        <title>Portfolio - Ethio Alert</title>
        <meta name="description" content="Portfolio page of Ethio Alert. Find out more about our services and mission." />
        <meta name="keywords" content="Portfolio, Ethio Alert, IT services, software development" />
        <meta property="og:title" content="Portfolio - Ethio Alert" />
        <meta property="og:description" content="Portfolio page of Ethio Alert. Find out more about our services and mission." />
        <meta property="og:image" content="URL to image for social media preview" />
      </Helmet>
      <Portfolioo/>
      <PortfolioTwo/>
      {/* Add portfolio components here */}
    </div>
  )
}

export default Portfolio