import React, { useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import Video from "../../assets/Video/codigplanet.mp4";
import Tech from "../../assets/Video/tech.mp4";
import { Button } from "antd";
import { Helmet } from 'react-helmet';

function Home() {
  const videoRef = useRef(null);

  useEffect(() => {
    AOS.init({ duration: 1200 }); // Initialize AOS with desired settings
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.3; // Adjust this value to set the desired playback speed
    }
  }, []);

  return (
    <div className="relative w-full h-screen overflow-hidden">
      <Helmet>
        <title>Home - Ethio Alert</title>
        <meta name="description" content="Home page of Ethio Alert. Find out more about our services and mission." />
        <meta name="keywords" content="home, Ethio Alert, IT services, software development" />
        <meta property="og:title" content="Home - Ethio Alert" />
        <meta property="og:description" content="Home page of Ethio Alert. Find out more about our services and mission." />
        <meta property="og:image" content="URL to image for social media preview" />
      </Helmet>
      <video
        ref={videoRef}
        className="absolute top-0 left-0 w-full h-full object-cover z-[-1] backdrop-blur-2xl"
        src={Video}
        autoPlay
        loop
        muted
      ></video>
      <div className="flex items-center justify-center h-full">
        <div className="flex flex-col lg:flex-row w-full max-w-7xl mx-auto gap-6 rounded-lg shadow-lg overflow-hidden">
          <div className="lg:w-1/2 flex flex-col justify-center items-start p-4 bg-black bg-opacity-40" data-aos="fade-right">
            <h1 className="text-4xl lg:text-5xl mb-4 text-[#FF7F00] font-bold">
              <span className="text-[#1A7FC3]">Ethio Alert:</span> Simplifying IT, speeding solutions, solving problems.
            </h1>
            <p className="text-lg lg:text-xl mb-4 text-white font-semibold">
              Ethio Alert specializes in IT consultation, cutting-edge software
              development, and seamless integration of websites, apps, systems,
              and APIs.
            </p>
            <div className="flex gap-4">
              <a href="/about">
                <Button className="bg-blue-800 text-white text-lg lg:text-xl p-4 mt-2" data-aos="zoom-in">
                  More
                </Button>
              </a>
              <a href="/contact">
                <Button className="bg-blue-800 text-white text-lg lg:text-xl p-4 mt-2" data-aos="zoom-in">
                  Contact Us
                </Button>
              </a>
            </div>
          </div>
          <div className="lg:w-1/2 flex justify-center items-center p-4 overflow-hidden" data-aos="fade-left">
            <video
              className="w-full h-auto"
              src={Tech}
              autoPlay
              loop
              muted
            ></video>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
