import React, { useEffect } from 'react';
import { CodeOutlined, MobileOutlined, PictureOutlined, BulbOutlined } from "@ant-design/icons";
import AOS from 'aos';
import 'aos/dist/aos.css';

function Services() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="bg-slate-800 text-white py-10">
      <div className="max-w-7xl mx-auto px-6 sm:px-10 md:px-14">
        <h2 className="text-3xl md:text-4xl font-bold text-center text-yellow-500 mb-10" data-aos="fade-up">
          OUR SERVICES
        </h2>
        <p className="text-lg text-center text-gray-100 mb-10 max-w-3xl mx-auto" data-aos="fade-up" data-aos-delay="200">
          Let Ethio Alert handle the details, so you can focus on what matters. We offer transformative Website Development solutions, dynamic Mobile App Development, inspiring Graphics Design, and powerful Digital Marketing strategies – Unleashing limitless potential.
        </p>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8">
          <div className="p-6 bg-slate-700 rounded-lg text-center shadow-lg" data-aos="fade-up" data-aos-delay="400">
            <CodeOutlined className="text-5xl text-yellow-500 mb-4" />
            <h3 className="text-2xl font-bold mb-2">Website Development</h3>
            <p className="text-gray-100">
              Creative solutions for crafting and enhancing your online presence. Unleashing possibilities for your vision.
            </p>
          </div>
          
          <div className="p-6 bg-slate-700 rounded-lg text-center shadow-lg" data-aos="fade-up" data-aos-delay="600">
            <MobileOutlined className="text-5xl text-yellow-500 mb-4" />
            <h3 className="text-2xl font-bold mb-2">Mobile App Development</h3>
            <p className="text-gray-100">
              Seamless solutions for developing native and cross-platform mobile applications. Empowering connectivity and user engagement.
            </p>
          </div>
          
          <div className="p-6 bg-slate-700 rounded-lg text-center shadow-lg" data-aos="fade-up" data-aos-delay="800">
            <PictureOutlined className="text-5xl text-yellow-500 mb-4" />
            <h3 className="text-2xl font-bold mb-2">Graphics Design</h3>
            <p className="text-gray-100">
              Inspiring designs from logos to UI/UX that resonate with your brand and captivate your audience.
            </p>
          </div>
          
          <div className="p-6 bg-slate-700 rounded-lg text-center shadow-lg" data-aos="fade-up" data-aos-delay="1000">
            <BulbOutlined className="text-5xl text-yellow-500 mb-4" />
            <h3 className="text-2xl font-bold mb-2">Digital Marketing</h3>
            <p className="text-gray-100">
              Dynamic strategies to fuel growth and engagement through targeted digital marketing solutions.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
