import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const MissionVision = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="py-12 bg-blue-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center" data-aos="fade-up">
          <h2 className="text-3xl font-extrabold text-yellow-500 sm:text-4xl">
            Community Involvement
          </h2>
          <p className="mt-4 text-lg text-white max-w-3xl mx-auto">
            At Ethio Alert, we believe in giving back to the community and making a positive impact through our corporate social responsibility initiatives. We are committed to supporting nonprofits, engaging in community involvement, and encouraging volunteer work among our team members.
          </p>
        </div>

        <div className="mt-10">
          <h3 className="text-2xl font-bold text-gray-100 text-center" data-aos="fade-up" data-aos-delay="200">
            Our Initiatives
          </h3>
          <div className="mt-6 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
            <div className="bg-white p-6 rounded-lg shadow-lg" data-aos="fade-up" data-aos-delay="400">
              <h4 className="text-xl font-semibold text-gray-800">Partnerships with Nonprofits</h4>
              <p className="mt-2 text-gray-600">
                We collaborate with various nonprofit organizations to support their missions and amplify their impact. Our partnerships focus on education, healthcare, and technology access.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg" data-aos="fade-up" data-aos-delay="600">
              <h4 className="text-xl font-semibold text-gray-800">Community Involvement</h4>
              <p className="mt-2 text-gray-600">
                Ethio Alert actively participates in community events and local projects that aim to improve the quality of life for residents. We believe in the power of community and strive to be a positive force in our neighborhood.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg" data-aos="fade-up" data-aos-delay="800">
              <h4 className="text-xl font-semibold text-gray-800">Volunteer Work</h4>
              <p className="mt-2 text-gray-600">
                Our team members are encouraged to volunteer their time and skills to causes they are passionate about. We provide opportunities and support for our employees to engage in meaningful volunteer work.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionVision;
