import { Outlet } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
// import ContinuedText from "../Components/ContinuedText";

const MainLayout = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="flex flex-col">
      <div className="pb-20 ">
        <Navbar />
      </div>
      <div>
        <Outlet />
      </div>
      <div className="">
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
