import React from 'react'
import Jobc from "../components/Job/Jobc"
import JobsC from "../components/Job/JobsC"
import { Helmet } from 'react-helmet';

function Job() {
  return (
    <div>
      <Helmet>
        <title>Job - Ethio Alert</title>
        <meta name="description" content="Job page of Ethio Alert. Find out more about our services and mission." />
        <meta name="keywords" content="Job, Ethio Alert, IT services, software development" />
        <meta property="og:title" content="Job - Ethio Alert" />
        <meta property="og:description" content="Job page of Ethio Alert. Find out more about our services and mission." />
        <meta property="og:image" content="URL to image for social media preview" />
      </Helmet>
      <Jobc/>
      <JobsC/>
    </div>
  )
}

export default Job