import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import LOGO from "../assets/Circulart Logo-03.png";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";

function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const changeLanguage = (value) => {
    i18n.changeLanguage(value);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    AOS.init({
      easing: "ease-in-out",
      once: false,
      mirror: true,
    });
  }, []);

  return (
    <header
      className={`fixed top-0 left-0 w-full z-50 py-4 px-4 sm:px-10 transition-all duration-300 ${
        isScrolled
          ? "bg-[#1A1A1A] shadow-md h-20" // Minimized state
          : "bg-[#1A1A1A] h-24" // Default state
      }`}
    >
      <div className="flex flex-wrap items-center justify-between gap-5 w-full">
        {/* Logo */}
        <div
          className={`transition-all duration-300 ${
            isScrolled ? "w-20 h-20 p-2" : "w-24 h-24 p-2"
          }`}
        >
          <NavLink to="/" className="flex items-center">
            <img
              src={LOGO}
              alt="logo"
              className={`transition-all duration-300 ${
                isScrolled ? "p-0" : "p-0"
              }`}
            />
          </NavLink>
        </div>

        {/* Hamburger Icon for Mobile */}
        <button
          onClick={toggleMenu}
          className="lg:hidden text-white"
        >
          <svg
            className="w-7 h-7"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>

        {/* Mobile Menu */}
        <div className={`lg:hidden ${isMenuOpen ? "block" : "hidden"}`}>
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-40" onClick={closeMenu}></div>
          <div className="fixed top-0 left-0 w-3/4 max-w-md bg-white h-full shadow-lg z-50 transform transition-transform duration-300 ease-in-out">
            <button
              onClick={closeMenu}
              className="absolute top-4 right-4 text-2xl font-bold text-gray-800"
            >
              ×
            </button>
            <ul className="flex flex-col items-center mt-16 space-y-6">
              <li>
                <NavLink
                  to="/"
                  className="text-gray-800 hover:text-[#FF7F00] font-semibold text-lg"
                  onClick={closeMenu}
                >
                  {t("Home")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about"
                  className="text-gray-800 hover:text-[#FF7F00] font-semibold text-lg"
                  onClick={closeMenu}
                >
                  {t("About us")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/services"
                  className="text-gray-800 hover:text-[#FF7F00] font-semibold text-lg"
                  onClick={closeMenu}
                >
                  {t("Services")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/portfolio"
                  className="text-gray-800 hover:text-[#FF7F00] font-semibold text-lg"
                  onClick={closeMenu}
                >
                  {t("Portfolio")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/blog"
                  className="text-gray-800 hover:text-[#FF7F00] font-semibold text-lg"
                  onClick={closeMenu}
                >
                  {t("Blog")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact"
                  className="text-gray-800 hover:text-[#FF7F00] font-semibold text-lg"
                  onClick={closeMenu}
                >
                  {t("Contact us")}
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to="/jobs"
                  className="text-gray-800 hover:text-[#FF7F00] font-semibold text-lg"
                  onClick={closeMenu}
                >
                  {t("Job")}
                </NavLink>
              </li> */}
            </ul>
          </div>
        </div>

        {/* Regular Menu - Hidden on Small Screens */}
        <div className="hidden lg:flex flex-grow justify-center items-center gap-x-5">
          <NavLink
            to="/"
            className={({ isActive, isPending }) =>
              isPending
                ? "text-yellow-600"
                : isActive
                ? "text-[#FF7F00] font-bold"
                : "text-white"
            }
          >
            {t("Home")}
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive, isPending }) =>
              isPending
                ? "text-yellow-600"
                : isActive
                ? "text-[#FF7F00] font-bold"
                : "text-white"
            }
          >
            {t("About us")}
          </NavLink>
          <NavLink
            to="/services"
            className={({ isActive, isPending }) =>
              isPending
                ? "text-yellow-600"
                : isActive
                ? "text-[#FF7F00] font-bold"
                : "text-white"
            }
          >
            {t("Services")}
          </NavLink>
          <NavLink
            to="/portfolio"
            className={({ isActive, isPending }) =>
              isPending
                ? "text-yellow-600"
                : isActive
                ? "text-[#FF7F00] font-bold"
                : "text-white"
            }
          >
            {t("Portfolio")}
          </NavLink>
          <NavLink
            to="/blog"
            className={({ isActive, isPending }) =>
              isPending
                ? "text-yellow-600"
                : isActive
                ? "text-[#FF7F00] font-bold"
                : "text-white"
            }
          >
            {t("Blog")}
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive, isPending }) =>
              isPending
                ? "text-yellow-600"
                : isActive
                ? "text-[#FF7F00] font-bold"
                : "text-white"
            }
          >
            {t("Contact us")}
          </NavLink>
          {/* <NavLink
            to="/jobs"
            className={({ isActive, isPending }) =>
              isPending
                ? "text-yellow-600"
                : isActive
                ? "text-[#FF7F00] font-bold"
                : "text-white"
            }
          >
            {t("Job")}
          </NavLink> */}
        </div>

        {/* Language Selector */}
        <div className="hidden lg:block">
          <a href="https://t.me/senay1992">
            <button className="font-bold text-white bg-blue-800 p-2 rounded-lg text-sm hidden lg:block">
              Direct Contact
            </button>
          </a>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
