import React, { useState, useEffect } from "react";
import { Card, Row, Col, Modal } from "antd";
import axios from 'axios';
import AOS from "aos";
import "aos/dist/aos.css";

const Portfolio = () => {
  const [websiteItems, setWebsiteItems] = useState([]);
  const [graphicsItems, setGraphicsItems] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGraphic, setSelectedGraphic] = useState(null);

  useEffect(() => {
    AOS.init({ duration: 1000 });

    // Fetch data for websites and sort by createdAt in ascending order (oldest first)
    axios.get('https://ethioalert.api.ethioalert.com/api/portfolios')
      .then(response => {
        const sortedWebsiteItems = response.data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        setWebsiteItems(sortedWebsiteItems);
      })
      .catch(error => {
        console.error('Error fetching website items:', error);
      });

    // Fetch data for graphics and sort by createdAt in ascending order (oldest first)
    axios.get('https://ethioalert.api.ethioalert.com/api/portfoliosgraphics')
      .then(response => {
        const sortedGraphicsItems = response.data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        setGraphicsItems(sortedGraphicsItems);
      })
      .catch(error => {
        console.error('Error fetching graphics items:', error);
      });
  }, []);

  const handleOpenModal = (graphic) => {
    setSelectedGraphic(graphic);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedGraphic(null);
  };

  return (
    <div className="bg-slate-700 py-10" id="portfolio">
      <div className="max-w-7xl mx-auto px-6 sm:px-10 md:px-14">
        <h2
          className="text-3xl md:text-5xl font-bold text-yellow-500 text-center mb-8"
          data-aos="fade-up"
        >
          Our Previous Works
        </h2>

        {/* Website Category */}
        <div className="mb-12">
          <h3
            className="text-2xl md:text-3xl font-semibold text-white text-center mb-6"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            Websites
          </h3>
          <Row gutter={[16, 16]}>
            {websiteItems.map((item, index) => (
              <Col key={index} xs={24} sm={12} md={8} data-aos="fade-up" data-aos-delay={index * 100}>
                <Card
                  hoverable
                  className="shadow-lg transition-transform duration-300 transform hover:scale-105"
                  cover={
                    <a href={item.caseStudy} target="_blank" rel="noopener noreferrer" className="relative w-full" style={{ paddingBottom: '75%' }}>
                      <img 
                        alt={item.projectName}
                        src={`https://ethioalert.api.ethioalert.com/${item.image}`}
                        className="absolute inset-0 w-full h-full object-contain rounded-md"
                      />
                    </a>
                  }
                >
                  <Card.Meta
                    title={<h3 className="text-center font-bold text-black">{item.projectName}</h3>}
                    description={<p className="text-center text-black">{item.description}</p>}
                  />
                </Card>
              </Col>
            ))}
          </Row>
        </div>

        {/* Graphic Works Category */}
        <div>
          <h3
            className="text-2xl md:text-3xl font-semibold text-white text-center mb-6"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            Graphic Design
          </h3>
          <Row gutter={[16, 16]}>
            {graphicsItems.map((item, index) => (
              <Col key={index} xs={24} sm={12} md={8} data-aos="zoom-in" data-aos-delay={index * 100}>
                <div
                  className="block cursor-pointer"
                  onClick={() => handleOpenModal(item)}
                >
                  <Card
                    hoverable
                    className="shadow-lg transition-transform duration-300 transform hover:scale-105"
                    cover={
                      <div className="relative w-full" style={{ paddingBottom: '75%' }}>
                        <img
                          alt={item.projectName}
                          src={`https://ethioalert.api.ethioalert.com/${item.image}`}
                          className="absolute inset-0 w-full h-full object-contain rounded-md"
                        />
                      </div>
                    }
                  >
                    <Card.Meta
                      title={<h3 className="text-center font-bold text-black">{item.projectName}</h3>}
                      description={<p className="text-center text-black">{item.description}</p>}
                    />
                  </Card>
                </div>
              </Col>
            ))}
          </Row>
        </div>

        {/* Modal for Graphic Items */}
        {selectedGraphic && (
          <Modal
            visible={isModalVisible}
            onCancel={handleCloseModal}
            footer={null}
            width={800}
            className="portfolio-modal"
          >
            <img
              alt={selectedGraphic.projectName}
              src={`https://ethioalert.api.ethioalert.com/${selectedGraphic.image}`}
              className="w-full h-[80vh] object-contain"
            />
            <h3 className="text-center mt-4">{selectedGraphic.projectName}</h3>
            <p className="text-center">{selectedGraphic.description}</p>
            
          </Modal>
        )}
      </div>
    </div>
  );
};

export default Portfolio;
