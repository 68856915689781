import { Button } from "antd";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

function AboutDesc() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="bg-slate-800 py-10">
      <div className="max-w-7xl mx-auto px-6 sm:px-8 md:px-10 lg:px-12">
        <div data-aos="fade-up">
          <h2 className="text-yellow-400 text-2xl py-2 text-center md:text-left font-semibold">
            WHO WE ARE
          </h2>
          <h1 className="text-4xl md:text-5xl font-extrabold text-white text-center md:text-left">
            Ethio Alert - Your Digital Partner
          </h1>
          <p className="py-6 text-gray-200 text-lg md:text-xl text-center md:text-left leading-relaxed">
            Welcome to Ethio Alert, where we are dedicated to revolutionizing
            digital experiences for businesses and individuals across Ethiopia.
            Specializing in website, application, and API development, along with
            cutting-edge solutions in machine learning and AI, we strive to
            deliver innovative and tailored solutions that meet our clients'
            unique needs.
            <br /><br />
            At Ethio Alert, we believe in the transformative power of technology
            to propel progress and growth across all sectors of Ethiopian society.
            Our commitment lies in leveraging our skills and expertise to pave the
            way towards a digital future for Ethiopia and Africa. By harnessing
            technology, we aim to create new opportunities, boost productivity,
            and enrich the lives of people throughout the region.
            <br /><br />
            Our team comprises talented and passionate developers dedicated to
            crafting functional, user-friendly solutions. We take pride in our
            attentive approach, listening closely to our clients to develop
            bespoke solutions that precisely align with their requirements.
            <br /><br />
            At Ethio Alert, our vision is driven by a world where technology
            positively impacts lives. Whether you're a small business, a large
            corporation, or an individual striving to achieve your goals through
            technology, we are here to support you. Contact us today to explore
            our services and discover how we can empower you to achieve your
            digital ambitions.
          </p>
        </div>
        <div
          className="bg-slate-900 rounded-lg p-8 shadow-lg mt-10"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div className="mb-8">
            <h3 className="text-2xl font-bold text-yellow-400">Mission</h3>
            <p className="text-lg text-gray-300 mt-2">
              To empower Ethiopian businesses and communities through innovative
              technology solutions that enhance efficiency, connectivity, and
              digital accessibility.
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-bold text-yellow-400">Vision</h3>
            <p className="text-lg text-gray-300 mt-2">
              To lead Ethiopia's digital transformation by leveraging
              cutting-edge technology, fostering economic growth, and enriching
              lives through sustainable digital solutions.
            </p>
          </div>
        </div>
        <div className="text-center md:text-left pt-6">
          <a href="/contact">
            <Button className="bg-blue-700 text-white font-bold py-3 px-6 mt-6 hover:bg-blue-800 transition-all duration-300 text-lg rounded-md shadow-md">
              Contact us
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default AboutDesc;
