import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import GD from "../../assets/Home/Graphics Design.jpg";
import DM from "../../assets/Home/Digital Markeing.jpg";
import MA from "../../assets/Home/mobile app.jpg";
import WD from "../../assets/Home/web development.jpg";

const ServiceCard = ({ title, description, imageUrl, aosType, aosDelay }) => {
  useEffect(() => {
    AOS.init({
      duration: 1200, // Adjusted duration
      once: true,
    });
  }, []);

  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg mx-auto my-4 bg-blue-900 text-yellow-400" data-aos={aosType} data-aos-delay={aosDelay}>
      <img className="w-full h-48 sm:h-64 object-cover object-center" src={imageUrl} alt={title} />
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2" data-aos={aosType} data-aos-delay={aosDelay}>
          {title}
        </div>
        <p className="text-gray-100 text-base" data-aos={aosType} data-aos-delay={aosDelay + 100}>
          {description}
        </p>
      </div>
    </div>
  );
};

const Services = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200, // Adjusted duration
      once: true,
    });
  }, []);

  const services = [
    {
      title: 'Web Development',
      description: 'We create responsive and scalable web applications tailored to your business needs.',
      imageUrl: WD,
      aosType: 'fade-up',
      aosDelay: '100',
    },
    {
      title: 'Graphics Design',
      description: 'From logos to UI/UX design, we craft visually appealing designs that resonate with your brand.',
      imageUrl: GD,
      aosType: 'fade-down',
      aosDelay: '200',
    },
    {
      title: 'Mobile App Development',
      description: 'Developing native and cross-platform mobile applications for iOS and Android.',
      imageUrl: MA,
      aosType: 'fade-left',
      aosDelay: '300',
    },
    {
      title: 'Digital Marketing',
      description: 'Driving growth through targeted digital marketing strategies and campaigns.',
      imageUrl: DM,
      aosType: 'fade-right',
      aosDelay: '400',
    },
  ];

  return (
    <div className="min-h-screen bg-slate-700 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl sm:leading-10 pt-5">
            What We Do
          </h2>
          <h2 className="text-xl leading-8 font-extrabold tracking-tight text-white sm:text-3xl sm:leading-10 mb-4 p-5">
            Transforming the way you embrace technology!
          </h2>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              title={service.title}
              description={service.description}
              imageUrl={service.imageUrl}
              aosType={service.aosType}
              aosDelay={service.aosDelay}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
