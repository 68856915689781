import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Space, Form, Input, Upload, message, Pagination } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const TeamMembersForm = () => {
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [memberList, setMemberList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(3);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [currentMemberItem, setCurrentMemberItem] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [updateFileList, setUpdateFileList] = useState([]);

  useEffect(() => {
    fetchMembers();
  }, []);

  const fetchMembers = async () => {
    try {
      const response = await axios.get('https://ethioalert.api.ethioalert.com/api/team-members');
      setMemberList(response.data);
    } catch (error) {
      console.error('Error fetching members:', error);
      message.error('Failed to fetch team members');
    }
  };

  const handleFileChange = ({ fileList }) => setFileList(fileList.slice(-1));

  const handleUpdateFileChange = ({ fileList }) => setUpdateFileList(fileList.slice(-1));

  const handleAddSubmit = async (values) => {
    if (!values.name || !values.position || !fileList.length) {
      message.error('Please fill in all fields and upload an image');
      return;
    }

    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('position', values.position);
    formData.append('image', fileList[0].originFileObj);

    try {
      await axios.post('https://ethioalert.api.ethioalert.com/api/team-members', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('Team member added successfully');
      fetchMembers();
      form.resetFields();
      setFileList([]);
    } catch (error) {
      console.error('Error adding member:', error);
      message.error('Failed to add team member');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this team member?')) {
      try {
        await axios.delete(`https://ethioalert.api.ethioalert.com/api/team-members/${id}`);
        message.success('Team member deleted successfully');
        fetchMembers();
      } catch (error) {
        console.error('Error deleting member:', error);
        message.error('Failed to delete team member');
      }
    }
  };

  const handleView = (record) => {
    Modal.info({
      title: 'View Team Member',
      width: 800,
      content: (
        <div>
          <p><strong>Name:</strong> {record.name}</p>
          <p><strong>Position:</strong> {record.position}</p>
          <p><strong>Image:</strong></p>
          <img src={`https://ethioalert.api.ethioalert.com/${record.image}`} alt={record.name} style={{ width: '100%', maxHeight: '400px' }} />
          {/* src={`https://ethioalert.api.ethioalert.com/${record.imageURL}`} */}
        </div>
      ),
      onOk() {},
    });
  };

  const handleUpdate = (record) => {
    setCurrentMemberItem(record);
    updateForm.setFieldsValue({
      name: record.name,
      position: record.position,
    });
    setUpdateFileList([]);
    setIsUpdateModalVisible(true);
  };

  const handleUpdateSubmit = async (values) => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('position', values.position);
    if (updateFileList.length > 0) {
      formData.append('image', updateFileList[0].originFileObj);
    }

    try {
      await axios.patch(`https://ethioalert.api.ethioalert.com/api/team-members/${currentMemberItem._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('Team member updated successfully');
      fetchMembers();
      setIsUpdateModalVisible(false);
    } catch (error) {
      console.error('Error updating member:', error);
      message.error('Failed to update team member');
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
    },
    // {
    //   title: 'Date',
    //   dataIndex: 'date',
    //   key: 'date',
    //   render: (text) => new Date(text).toLocaleDateString(),
    // },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => handleView(record)}>View</Button>
          <Button onClick={() => handleUpdate(record)}>Update</Button>
          <Button danger onClick={() => handleDelete(record._id)}>Delete</Button>
        </Space>
      ),
    },
  ];

  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = memberList.slice(startIndex, endIndex);

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Team Members Manager</h2>

      <Form form={form} onFinish={handleAddSubmit}>
        <Form.Item name="name" rules={[{ required: true, message: 'Please enter the name' }]}>
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item name="position" rules={[{ required: true, message: 'Please enter the position' }]}>
          <Input placeholder="Position" />
        </Form.Item>
        <Form.Item name="image" rules={[{ required: true, message: 'Please upload an image' }]}>
          <Upload fileList={fileList} onChange={handleFileChange} beforeUpload={() => false} listType="picture">
            <Button icon={<UploadOutlined />}>Upload Image</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add Team Member
          </Button>
        </Form.Item>
      </Form>

      <Table columns={columns} dataSource={paginatedData} pagination={false} rowKey="_id" />

      <div className="pagination-container mt-4">
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={memberList.length}
          onChange={handlePaginationChange}
        />
      </div>

      <Modal
        title="Update Team Member"
        visible={isUpdateModalVisible}
        onCancel={() => setIsUpdateModalVisible(false)}
        footer={null}
      >
        <Form form={updateForm} onFinish={handleUpdateSubmit}>
          <Form.Item name="name" rules={[{ required: true, message: 'Please enter the name' }]}>
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item name="position" rules={[{ required: true, message: 'Please enter the position' }]}>
            <Input placeholder="Position" />
          </Form.Item>
          <Form.Item name="image">
            <Upload fileList={updateFileList} onChange={handleUpdateFileChange} beforeUpload={() => false} listType="picture">
              <Button icon={<UploadOutlined />}>Upload New Image (Optional)</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Team Member
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TeamMembersForm;
