import React from 'react'
import ServicesC from '../components/Services/ServicesC'
import OurServices from "../components/Services/OurServices"
import ServiceTwo from "../components/Services/ServiceTwo"
import { Helmet } from 'react-helmet';

function Services() {
  return (
    <div>
      <Helmet>
        <title>Services - Ethio Alert</title>
        <meta name="description" content="Home page of Ethio Alert. Find out more about our services and mission." />
        <meta name="keywords" content="Services, Ethio Alert, IT services, software development ,Graphics Design" />
        <meta property="og:title" content="Services - Ethio Alert" />
        <meta property="og:description" content="Services page of Ethio Alert. Find out more about our services and mission." />
        <meta property="og:image" content="URL to image for social media preview" />
      </Helmet>
      <ServicesC/>
      <OurServices/>
      <ServiceTwo/>
    </div>
  )
}

export default Services