import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, Space, Form, Input, Upload, message, Pagination } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const AddBlogForm = () => {
  const [form] = Form.useForm();
  const [updateForm] = Form.useForm();
  const [blogList, setBlogList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(3);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [currentBlogItem, setCurrentBlogItem] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [updateFileList, setUpdateFileList] = useState([]);

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get('https://ethioalert.api.ethioalert.com/api/posts');
      setBlogList(response.data);
    } catch (error) {
      console.error('Error fetching blogs:', error);
      message.error('Failed to fetch blogs');
    }
  };

  const handleFileChange = ({ fileList }) => setFileList(fileList.slice(-1));

  const handleUpdateFileChange = ({ fileList }) => setUpdateFileList(fileList.slice(-1));

  const handleAddSubmit = async (values) => {
    if (!values.title || !values.content || !fileList.length) {
      message.error('Please fill in all fields and upload an image');
      return;
    }

    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('content', values.content);
    formData.append('image', fileList[0].originFileObj);

    try {
      await axios.post('https://ethioalert.api.ethioalert.com/api/posts', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('Blog added successfully');
      fetchBlogs();
      form.resetFields();
      setFileList([]);
    } catch (error) {
      console.error('Error adding blog:', error);
      message.error('Failed to add blog');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this blog?')) {
      try {
        await axios.delete(`https://ethioalert.api.ethioalert.com/api/posts/${id}`);
        message.success('Blog deleted successfully');
        fetchBlogs();
      } catch (error) {
        console.error('Error deleting blog:', error);
        message.error('Failed to delete blog');
      }
    }
  };

  const handleView = (record) => {
    Modal.info({
      title: 'View Blog',
      width: 800,
      content: (
        <div>
          <p><strong>Title:</strong> {record.title}</p>
          <p><strong>Content:</strong> {record.content}</p>
          <p><strong>Image:</strong></p>
          <img  src={`https://ethioalert.api.ethioalert.com/${record.imageURL}`} alt={record.title} style={{ width: '100%', maxHeight: '400px' }} />
          
        </div>
      ),
      onOk() {},
    });
  };

  const handleUpdate = (record) => {
    setCurrentBlogItem(record);
    updateForm.setFieldsValue({
      title: record.title,
      content: record.content,
    });
    setUpdateFileList([]);
    setIsUpdateModalVisible(true);
  };

  const handleUpdateSubmit = async (values) => {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('content', values.content);
    if (updateFileList.length > 0) {
      formData.append('image', updateFileList[0].originFileObj);
    }

    try {
      await axios.patch(`https://ethioalert.api.ethioalert.com/api/posts/${currentBlogItem._id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      message.success('Blog updated successfully');
      fetchBlogs();
      setIsUpdateModalVisible(false);
    } catch (error) {
      console.error('Error updating blog:', error);
      message.error('Failed to update blog');
    }
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => handleView(record)}>View</Button>
          <Button onClick={() => handleUpdate(record)}>Update</Button>
          <Button danger onClick={() => handleDelete(record._id)}>Delete</Button>
        </Space>
      ),
    },
  ];

  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = blogList.slice(startIndex, endIndex);

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Blog Manager</h2>

      <Form form={form} onFinish={handleAddSubmit}>
        <Form.Item name="title" rules={[{ required: true, message: 'Please enter the title' }]}>
          <Input placeholder="Title" />
        </Form.Item>
        <Form.Item name="content" rules={[{ required: true, message: 'Please enter the content' }]}>
          <TextArea placeholder="Content" rows={4} />
        </Form.Item>
        <Form.Item name="image" rules={[{ required: true, message: 'Please upload an image' }]}>
          <Upload fileList={fileList} onChange={handleFileChange} beforeUpload={() => false} listType="picture">
            <Button icon={<UploadOutlined />}>Upload Image</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add Blog
          </Button>
        </Form.Item>
      </Form>

      <Table columns={columns} dataSource={paginatedData} pagination={false} rowKey="_id" />

      <div className="pagination-container mt-4">
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={blogList.length}
          onChange={handlePaginationChange}
        />
      </div>

      <Modal
        title="Update Blog"
        visible={isUpdateModalVisible}
        onCancel={() => setIsUpdateModalVisible(false)}
        footer={null}
      >
        <Form form={updateForm} onFinish={handleUpdateSubmit}>
          <Form.Item name="title" rules={[{ required: true, message: 'Please enter the title' }]}>
            <Input placeholder="Title" />
          </Form.Item>
          <Form.Item name="content" rules={[{ required: true, message: 'Please enter the content' }]}>
            <TextArea placeholder="Content" rows={4} />
          </Form.Item>
          <Form.Item name="image">
            <Upload fileList={updateFileList} onChange={handleUpdateFileChange} beforeUpload={() => false} listType="picture">
              <Button icon={<UploadOutlined />}>Upload New Image (Optional)</Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update Blog
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddBlogForm;
