import React from "react";
import { FaDesktop, FaMobileAlt, FaPaintBrush, FaBullhorn } from "react-icons/fa";
import WD from "../../assets/Home/Worldthree.png"; // Replace with actual image path
import AOS from "aos";
import "aos/dist/aos.css";

const ServicesOverview = () => {
  React.useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <section className="bg-slate-700 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row items-center">
        <div className="w-full lg:w-1/2 lg:pr-8 mb-8 lg:mb-0">
          <h2 className="text-xl lg:text-2xl font-bold text-yellow-500 mb-4" data-aos="fade-up">
            OUR SERVICES
          </h2>
          <p className="text-base lg:text-sm text-white mb-6" data-aos="fade-up" data-aos-delay="100">
            Let Ethio Alert handle the details, so you can focus on what
            matters. We offer transformative Website Development solutions,
            dynamic Mobile App Development, inspiring Graphics Design, and
            powerful Digital Marketing strategies – Unleashing limitless
            potential.
          </p>
          <div className="mb-6" data-aos="fade-up" data-aos-delay="200">
            <div className="flex items-center mb-2">
              <FaDesktop className="text-3xl text-blue-600 mr-3" />
              <h3 className="text-lg lg:text-xl font-bold text-yellow-500">
                Website Development
              </h3>
            </div>
            <p className="text-sm lg:text-base text-white mb-2">
              Creative solutions for crafting and enhancing your online
              presence. Unleashing possibilities for your vision.
            </p>
          </div>
          <div className="mb-6" data-aos="fade-up" data-aos-delay="300">
            <div className="flex items-center mb-2">
              <FaMobileAlt className="text-3xl text-blue-600 mr-3" />
              <h3 className="text-lg lg:text-xl font-bold text-yellow-500">
                Mobile App Development
              </h3>
            </div>
            <p className="text-sm lg:text-base text-white mb-2">
              Seamless solutions for developing native and cross-platform mobile
              applications. Empowering connectivity and user engagement.
            </p>
          </div>
          <div className="mb-6" data-aos="fade-up" data-aos-delay="400">
            <div className="flex items-center mb-2">
              <FaPaintBrush className="text-3xl text-blue-600 mr-3" />
              <h3 className="text-lg lg:text-xl font-bold text-yellow-500">
                Graphics Design
              </h3>
            </div>
            <p className="text-sm lg:text-base text-white mb-2">
              Inspiring designs from logos to UI/UX that resonate with your
              brand and captivate your audience.
            </p>
          </div>
          <div data-aos="fade-up" data-aos-delay="500">
            <div className="flex items-center mb-2">
              <FaBullhorn className="text-3xl text-blue-600 mr-3" />
              <h3 className="text-lg lg:text-xl font-bold text-yellow-500">
                Digital Marketing
              </h3>
            </div>
            <p className="text-sm lg:text-base text-white mb-4">
              Dynamic strategies to fuel growth and engagement through targeted
              digital marketing solutions.
            </p>
          </div>
        </div>
        <div className="w-full lg:w-1/2">
          <img
            src={WD} // Replace with the appropriate image for Website Development
            alt="Website Development"
            className="rounded-lg  h-auto"
            data-aos="fade-left"
            data-aos-duration="1000"
          />
        </div>
      </div>
    </section>
  );
};

export default ServicesOverview;
