import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { message } from 'antd';

const TeamMembers = () => {
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    AOS.init({ duration: 1000 });
    fetchTeamMembers();
  }, []);

  const fetchTeamMembers = async () => {
    try {
      const response = await axios.get('https://ethioalert.api.ethioalert.com/api/team-members');
      setTeamMembers(response.data);
    } catch (error) {
      console.error('Error fetching team members:', error);
      message.error('Failed to fetch team members');
    }
  };

  return (
    <div className="bg-gray-300 py-12 px-4">
      <h2 className="text-4xl font-bold text-center mb-12 text-gray-800">Our Team</h2>
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {teamMembers.map((member, index) => (
          <div key={index} className="bg-white rounded-lg shadow-lg overflow-hidden transform transition duration-300 hover:scale-105" data-aos="fade-up">
            <div className="group relative">
              <img
                src={`https://ethioalert.api.ethioalert.com/${member.image}`} 
                alt={member.name}
                className="w-full h-64 object-fill"
              />
            </div>
            <div className="p-6">
              <h3 className="text-2xl font-semibold text-gray-800 text-center">{member.name}</h3>
              <p className="text-gray-600 text-center">{member.position}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamMembers;
