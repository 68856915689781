import React from 'react'
import Contactus from ".././components/Cotactus/contactus"
import { Helmet } from 'react-helmet';
function ContactUs() {
  return (
    <div>
      <Helmet>
        <title>Contact - Ethio Alert</title>
        <meta name="description" content="Contact page of Ethio Alert. Find out more about our services and mission." />
        <meta name="keywords" content="Contact, Ethio Alert, IT services, software development" />
        <meta property="og:title" content="Contact - Ethio Alert" />
        <meta property="og:description" content="Contact page of Ethio Alert. Find out more about our services and mission." />
        <meta property="og:image" content="URL to image for social media preview" />
      </Helmet>
     <Contactus/>
    </div>
  )
}

export default ContactUs