import React from 'react'
import LandingPage from "../components/Home/LandingPage"
import Whatwedo from "../components/Home/Whatwedo"
import Banner from "../components/Home/Banner"
import Services from '../components/Home/Services'
import HowItWorks from '../components/Home/Howitworks'
import Testimonials from '../components/Home/Testimonials'
import ContactUs from '../components/Home/Contactus'
function Home() {
  return (
    <div>
      <div>
        <LandingPage/>
      </div>
      <div>
        <Whatwedo/>
      </div>
      <div>
        <Banner/>
      </div>
      <div>
        <Services/>
      </div>
      <div>
        <HowItWorks/>
      </div>
      <div>
        <ContactUs/>
      </div>
      <div>
      <Testimonials/>
      </div>
      
    </div>
  )
}

export default Home