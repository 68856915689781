import React from 'react'
import Navbar from "../components/Admin/NavbarA"

function Admin() {
  return (
    <div>
     <Navbar/>
    </div>
  )
}

export default Admin