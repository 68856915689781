import React, { useState } from "react";
import {
  FaHome,
  FaComments,
  FaNewspaper,
  FaBullhorn,
  FaImages,
  FaUserTie,
  FaInfoCircle,
  FaSignOutAlt,
} from "react-icons/fa";
import AddPosts from "./AddPosts";
import LoginForm from "./LoginForm";
import ViewContactUs from "./ViewContactUs";
import AddPortfolio from "./AddPortfolio";
import AddTeam from "./AddTeam"
import AddTestimonial from "./AddTestimonial"

const NavbarA = () => {
  const [currentPage, setCurrentPage] = useState("home"); // State to track current page
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Function to handle navigation to different pages
  const navigateToPage = (page) => {
    setCurrentPage(page);
  };

  // Function to handle login
  const handleLogin = (username, password) => {
    // Perform login logic here (e.g., API call, authentication)
    if (username === "ethio" && password === "ethio") {
      setIsAuthenticated(true);
    } else {
      alert("Invalid credentials");
    }
  };

  // Function to handle logout
  const handleLogout = () => {
    setIsAuthenticated(false);
    setCurrentPage("home");
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen">
      {!isAuthenticated ? (
        <LoginForm onLogin={handleLogin} />
      ) : (
        <>
          {/* Sidebar */}
          <aside className="bg-gray-800 text-gray-100 border-r shadow-lg flex flex-col justify-between w-64">
            <div className="flex flex-col mt-4">
              <nav className="flex flex-col">
                <a
                  href="#"
                  className={`flex items-center px-4 py-3 text-lg font-semibold rounded-md transition-colors duration-300 ${
                    currentPage === "addportfolio"
                      ? "text-yellow-400 bg-gray-900"
                      : "text-gray-300 hover:bg-gray-700"
                  }`}
                  onClick={() => navigateToPage("addportfolio")}
                >
                  <FaImages className="mr-4 text-xl" /> Add Portfolio
                </a>
                <a
                  href="#"
                  className={`flex items-center px-4 py-3 text-lg font-semibold rounded-md transition-colors duration-300 ${
                    currentPage === "addposts"
                      ? "text-yellow-400 bg-gray-900"
                      : "text-gray-300 hover:bg-gray-700"
                  }`}
                  onClick={() => navigateToPage("addposts")}
                >
                  <FaNewspaper className="mr-4 text-xl" /> Add Posts
                </a>

                <a
                  href="#"
                  className={`flex items-center px-4 py-3 text-lg font-semibold rounded-md transition-colors duration-300 ${
                    currentPage === "addteam"
                      ? "text-yellow-400 bg-gray-900"
                      : "text-gray-300 hover:bg-gray-700"
                  }`}
                  onClick={() => navigateToPage("addteam")}
                >
                  <FaNewspaper className="mr-4 text-xl" /> Add Team
                </a>


                <a
                  href="#"
                  className={`flex items-center px-4 py-3 text-lg font-semibold rounded-md transition-colors duration-300 ${
                    currentPage === "addtestimonial"
                      ? "text-yellow-400 bg-gray-900"
                      : "text-gray-300 hover:bg-gray-700"
                  }`}
                  onClick={() => navigateToPage("addtestimonial")}
                >
                  <FaNewspaper className="mr-4 text-xl" /> Add Testimonial
                </a>
                <a
                  href="#"
                  className={`flex items-center px-4 py-3 text-lg font-semibold rounded-md transition-colors duration-300 ${
                    currentPage === "viewcontactus"
                      ? "text-yellow-400 bg-gray-900"
                      : "text-gray-300 hover:bg-gray-700"
                  }`}
                  onClick={() => navigateToPage("viewcontactus")}
                >
                  <FaInfoCircle className="mr-4 text-xl" /> View Contact
                </a>
              </nav>
            </div>
            <button
              onClick={handleLogout}
              className="text-white bg-red-600 hover:bg-red-700 px-4 py-2 rounded-md mt-4 mx-4 mb-6 flex items-center transition-colors duration-300"
            >
              <FaSignOutAlt className="mr-2 text-lg" /> Logout
            </button>
          </aside>

          {/* Main Content */}
          <main className="flex-1 bg-gray-100 p-6">
            {/* Conditional rendering based on currentPage state */}
            {currentPage === "addposts" && <AddPosts />}
            {currentPage === "viewcontactus" && <ViewContactUs />}
            {currentPage === "addportfolio" && <AddPortfolio />}
            {currentPage === "addteam" && <AddTeam />}
            {currentPage === "addtestimonial" && <AddTestimonial />}
          </main>
        </>
      )}
    </div>
  );
};

export default NavbarA;
